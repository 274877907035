@import "../../theme/colors";
@import url("https://use.typekit.net/sui0tfd.css");

.model-profile {
  h1.model-title {
	font-family   : aw-conqueror-didot, serif;
	line-height   : 1;
	font-size     : 2.5rem;
	margin-bottom : 1rem;
  }

  .booking-code {
	margin-bottom : 1.5rem;
	line-height   : 1;
	font-size     : 2rem;
  }

  .model-address {
	margin-bottom : 0;
	line-height   : 1;
	font-weight   : 600;
	font-size     : 0.9rem;
  }

  .main-data {
	padding-bottom : 3rem;

	label {
	  font-size     : 1.25rem;
	  font-weight   : 400;
	  margin-bottom : 1rem;
	}

	.model-data-field {
	  font-size   : 2.25rem;
	  font-weight : 400;
	}
  }

}

.model-details {
  line-height : 1;

  .details-toggle {
	color       : $primary;
	font-size   : 1.5rem;
	font-weight : 400;
	text-align  : center;
	padding     : 1.25rem 0;
	cursor      : pointer;
  }

  .details-row {
	label {
	  font-weight   : 600;
	  margin-bottom : 0;
	}
  }

}

.model-desc {
  padding : 3rem 0;

  p {
	text-align  : center;
	font-size   : 1.25rem;
	white-space : pre-wrap;
  }
}

.model-actions {
  .model-action {
	text-align      : center;
	text-decoration : none !important;
	color           : $primary;
	cursor          : pointer;
	padding         : 0.75rem 1rem;
	margin-bottom   : 1rem;
	transition      : 0.4s;

	i {
	  font-size : 2rem;
	}

	.action-title {
	  font-size : 0.75rem;
	}

	&:hover {
	  background : $primary;
	  color      : #FFF !important;
	}

	.action-title {
	  padding-top : 0.5rem;
	  font-weight : 300;
	}

	&.active-favorit {
	  //color : darken($primary, 25%) !important;
	}
  }
}

@media (min-width : 1199px) {
  .model-profile {
	.main-data {
	  .middle-data {
		border-left  : 1px solid rgba(0, 0, 0, 0.1);
		border-right : 1px solid rgba(0, 0, 0, 0.1);
	  }
	}
  }
}

@media (max-width : 1199px) {
  .model-profile {
	.main-data {

	  label {
		font-size     : 1rem;
		font-weight   : 400;
		margin-bottom : 0;
	  }

	  .model-data-field {
		font-size     : 1.25rem;
		font-weight   : 400;
		margin-bottom : 0;
	  }
	}
  }
}
