@import "../../theme/colors";

.approve-image {
  position : relative;

  .edit-button {
	position      : absolute;
	top           : 5px;
	right         : 5px;
	border-radius : 50%;
	background    : $primary;
	color         : #FFF;
	height        : 30px;
	width         : 30px;
	text-align    : center;
	line-height   : 30px;
	cursor        : pointer;
  }

  .delete-button {
	position      : absolute;
	top           : 5px;
	left          : 5px;
	border-radius : 50%;
	background    : $danger;
	color         : #FFF;
	height        : 30px;
	width         : 30px;
	text-align    : center;
	line-height   : 30px;
	cursor        : pointer;
  }
}

.admin-comment {
  .alert {
	margin-top    : 5px;
	margin-bottom : 0;
	font-size     : 12px;
	display       : inline-block !important;
	width         : auto;

	i {
	  font-size : 16px;
	}
  }
}
.rs-list-sortable{
  width: 100%;
}
.admin-sort-item {
  width: 100%;
  img {
	max-width : 100px;
  }
}

.reorderArrow {
  display         : flex;
  cursor          : pointer;
  justify-content : center;
  font-size       : 1.25rem;
  color           : $primary;
}
