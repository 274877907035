.confirm-delete {
  p {
	font-size     : 1.5rem;
	margin-bottom : 0;
  }

  .buttons {
	padding-top : 1rem;

	i {
	  font-size : 2rem;
	  cursor    : pointer;
	  padding   : 0 1rem;
	}
  }
}
