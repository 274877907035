// @flow
// variables.scss
$white                : #FFF;
$gold                 : #A68018;
$lightBackground      : #ECECEC;
$text                 : #313131;
$textLight            : #7A7E8A;
$primary              : #B9B2A2;
$primaryLight         : #D7CFBD;
$primaryNeon          : #97BF04;
$primaryDark          : #365902;
$red                  : #FF3008;
$danger               : #F32129;
$success              : #4AAF28;
$primaryLight2        : #D2EA9C;
$primaryGrey          : #3C3C3B;
$brown                : #E2D3BC;

$lightGreenBackground : mix($primary, $white, 5);

// the :export directive is the magic sauce for webpack
//noinspection ALL
:export {
  white           : $white;
  lightBackground : $lightBackground;
  text            : $text;
  gold            : $primary;
  primary         : $primary;
  primaryLight    : $primaryLight;
  primaryNeon     : $primaryNeon;
  primaryDark     : $primaryDark;
  primaryDark     : $primaryDark;
}
