@import "../../theme/colors";
@import url("https://use.typekit.net/sui0tfd.css");

.model-favourit-card {
  border     : 1px solid rgba(0, 0, 0, 0.1);
  background : #FFF;

  .favourites-card-inner {
	background : #FFF;
	padding    : 1rem;
	text-align : center;

	.model-title {
	  font-family : aw-conqueror-didot, serif;
	  line-height : 1;
	  font-size   : 2.5rem;
	}

	.model-location {
	  font-size : 1.25rem;
	}

  }

  .model-teaser-actions {
	.model-action {
	  min-width       : 92px;
	  cursor          : pointer;
	  color           : $primary;
	  transition      : 0.4s;
	  text-decoration : none !important;

	  i {
		font-size : 1.5rem;
	  }

	  .action-title {
		font-size : 0.75rem;
	  }

	  &:hover {
		color : darken($primary, 15%);
	  }
	}
  }
}

@media (min-width : 1300px) {
  .favourites-card-inner {
	padding : 5rem !important;
  }
}

@media (min-width : 1199px) {
  .favourite-padding {
	padding-right : 8rem !important;
  }
  .favourites-card-inner {
	padding : 4rem 2rem !important;
  }
}

@media (max-width : 1199px) {
  .model-action {;
	min-width : 33% !important;
  }
}

.share-icon{
  padding: 12px 10px;
  font-size: 22px;
  margin-left: 15px;
  line-height: 22px;
  display: inline-block;
  color: #fff;
  cursor: pointer;
  background: $primary;
  i{
	margin-right: 10px;
  }
}

.share-container{
  border: 1px solid $primary;
  background: #fff;
}

.close-icon{
  font-size: 20px;
  cursor: pointer;
}

.no-border-textarea{
  textarea{
	border: none !important;
	padding: 0;
	margin-top: 5px;
  }
}
