@import "../../theme/colors";
@import url("https://use.typekit.net/sui0tfd.css");

.model-card {
  overflow : hidden;
  position : relative;

  img {
	object-fit : contain;
  }

  .info {
	position   : absolute;
	width      : 100%;
	background : transparentize($primary, 0.6);
	padding    : 1.25rem;
	bottom     : 0;
	left       : 0;
	text-align : center;

	h1 {
	  font-family : aw-conqueror-didot, serif;
	}

	p {
	  margin-bottom : 0;
	}
  }
}

.filter-holder {
  .form-control {
	color      : #FFF;
	border     : 1px solid #FFF;
	background : transparent;
  }

  .filter-label {
	color         : #FFF;
	margin-bottom : 1rem;
	font-weight   : 300;
  }

  .rs-slider {
	padding-top    : 18px;
	padding-bottom : 18px;
  }

  .rs-slider-bar {
	height           : 2px;
	border-radius    : 0;
	background-color : #FFF;
	cursor           : pointer;
  }

  .rs-slider-progress-bar {
	position         : absolute;
	height           : 20px;
	border-radius    : 3px 0 0 3px;
	margin-top       : -9px;
	background-color : rgba(255, 255, 255, 0.2);
  }

  .rs-slider-handle {
	position : absolute;
	top      : calc(-50% + 19px);
  }

  .rs-slider-handle::before {
	content          : '';
	position         : absolute;
	width            : 15px;
	height           : 40px;
	border-radius    : 0;
	border           : 2px solid #FFF;
	background-color : #FFF;
	margin-left      : -7px;
	margin-bottom    : -15px;
	cursor           : pointer;
	transition       : box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

  .rs-picker-default .rs-btn, .rs-picker-input .rs-btn, .rs-picker-default .rs-picker-toggle, .rs-picker-input .rs-picker-toggle {
	background : transparent !important;
  }

  .rs-picker-default a.rs-btn.rs-btn-default.rs-picker-toggle {
	border-color  : #E1E1E1;
	border-radius : 0;
	padding       : 0.65rem 0.75rem;
  }

  .less-margin-bottom {
	margin-bottom : 12px;
  }

  .rs-picker-toggle-placeholder {
	color       : #FFF;
	font-weight : 300;
  }

  .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
	color       : #FFF;
	font-weight : 300;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
	color : #FFF;
	top   : 10px;
  }


  .applyFilter-btn, .clear-filter {
	background : #FFF;
	color      : $primary;
	padding    : 1rem;
	cursor     : pointer;
	font-size  : 1.45rem;
  }
}

.accordion {
  background : $primary;

  .card-body {
	background     : $primary !important;
	padding-bottom : 0;
  }

  .card-header {

	background : transparent;
	padding    : 1.25rem 0;

	.btn {
	  background      : transparent;
	  font-size       : 1.25rem;
	  text-decoration : none;
	  color           : #FFF !important;
	}

	.btn:hover, .btn:focus, .btn:active {
	  background : transparent !important;
	}

	&.active {
	  background : $primary;

	  .btn {
		color : #FFF !important;
	  }
	}
  }

  .card {
	background : transparent;
	border     : none;
  }

  &:hover {
	background : darken($primary, 20%);
  }

  .collapse.show {
	background : $primary;
  }


}

.rs-picker-menu {
  border-radius : 0 !important;
}

.model-swipe-container {
  position : relative;
  width    : 100%;
  overflow : hidden;

  .model-swipe-container-inner {
	width  : auto;
	height : 100%;

	.model-img {
	  display : inline-block;
	}
  }
}

.interface-overlay {
  position        : absolute;
  width           : 100%;
  height          : 100%;
  left            : 0;
  top             : 0;
  z-index         : 99;
  display         : flex;
  flex-direction  : row;
  align-items     : stretch;
  justify-content : center;

  .prev-model {
	flex-grow : 1;
	position  : relative;
  }

  .current-model {
	position : relative;

	img {
	  opacity : 0;
	}
  }

  .next-model {
	flex-grow : 1;
	position  : relative;
  }

  .model-switch-button-container {
	position   : absolute;
	left       : 0;
	top        : 0;
	width      : 100%;
	height     : 100%;
	background : transparentize($primary, 0.6);

	.model-switch-button {
	  position  : absolute;
	  top       : 50%;
	  left      : 50%;
	  transform : translate(-50%, -50%);
	  font-size : 1.8rem;
	  color     : #FFF;
	  cursor    : pointer;
	}
  }
}

.model-search-min-height {
  min-height : 70vh;
}


@media (max-width : 767px) {
  .model-search-min-height {
	min-height : 80vw;
  }
  .model-swipe-container, .interface-overlay {

	.prev-model {
	  min-width : 10vw;
	}

	.current-model {
	  max-width : 80vw;
	}

	.next-model {
	  min-width : 10vw;
	}
  }
}

.bg-position-center {
  background-position : center;
  background-size     : cover;
}

.model-actions {
  position   : relative;
  background : #FFF;
  width      : 100%;

  .model-action {
	min-width  : 165px;
	text-align : center;
	color      : $primary;
	cursor     : pointer;
	padding    : 1rem 1.25rem;
	margin     : 0 1rem;
	transition : 0.4s;

	&:hover {
	  background : $primary;
	  color      : #FFF;
	}

	.action-title {
	  padding-top : 0.5rem;
	  font-weight : 300;
	}
  }
}

.model-teaser-holder {
  .toggle-style {
	cursor : pointer;
	color  : $primary;

	&:hover {
	  color : #000;
	}
  }
}

.model-teaser {
  border        : 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom : 15px;

  .model-teaser-info-inner {
	background : #FFF;

	.booking-code {
	  font-size : 1.25rem;
	}
  }

  .info {
	padding    : 1.5rem;
	text-align : center;

	p {
	  margin      : 0 0 2px;
	  line-height : 1;
	}
  ;

	.model-title {
	  font-size   : 1.5rem;
	  font-family : aw-conqueror-didot, serif;
	}
  }

  .model-teaser-actions {
	padding : 0 1rem;

	.model-action {
	  min-width       : 30%;
	  text-align      : center;
	  text-decoration : none !important;
	  color           : $primary;
	  cursor          : pointer;
	  padding         : 0.75rem 1rem;
	  margin-bottom   : 1rem;
	  transition      : 0.4s;

	  i {
		font-size : 1.5rem;
	  }

	  .action-title {
		font-size : 0.75rem;
	  }

	  &:hover {
		background : $primary;
		color      : #FFF !important;
	  }

	  .action-title {
		padding-top : 0.5rem;
		font-weight : 300;
	  }

	  &.active-favorit {
		//color : darken($primary, 25%) !important;
	  }
	}
  }
}

.active-favorit {
  //color : darken($primary, 25%) !important;
}

.toggle-style {
  padding    : 2rem 0;
  width      : 142px;
  height     : 90px;
  text-align : center;
  position   : absolute;
  right      : 0;
  top        : 15px;
  cursor     : pointer;
  color      : $primary;
  z-index    : 9999;

  span {
	display    : block;
	margin-top : 2px;
	font-size  : 12px;
  }

  &:hover {
	color : #000 !important;
  }
}

.toggle-clearFilter {
  padding    : 2rem 0;
  width      : 142px;
  height     : 90px;
  text-align : center;
  position   : absolute;
  right      : 150px;
  top        : 15px;
  cursor     : pointer;
  color      : #FFF;
  z-index    : 9999;

  span {
	display    : block;
	margin-top : 2px;
	font-size  : 12px;
  }

  &:hover {
	color : #000 !important;
  }
}

@media (max-width : 991px) {
  .toggle-clearFilter-holder{
	display: none!important;
  }
}

.accordion {
  .toggle-style {
	color : #FFF;
  }
}


.current-model {
  cursor : pointer;
}

.model-swipe-container-inner {
  transition : 0.4s;
}

.price-type-holder {
  .rs-picker-select {
	.rs-btn {
	  padding-right : 3rem !important;
	}
  }
}

.click-here-text {
  font-size : 0.85rem;
}

.price-filter-text {
  .form-control {
	width : 100px !important;
  }
}

.clothing-size-selection {
  .clothing-size-select {
	width : 140px;
  }

  .price-type-holder {
	.rs-picker-toggle {
	  padding-right : 3rem !important;
	}
  }
}

