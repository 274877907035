@import "../../theme/colors";
@import url("https://use.typekit.net/sui0tfd.css");

.model-list-item {
  padding       : 10px;
  border        : 1px solid $primary;
  margin-bottom : 1rem;

  .model-name {
	font-size   : 1.5rem;
	font-family : aw-conqueror-didot, serif;
  }

  .btn {
	font-size : 1rem;
	padding   : 5px;
  }
}
