@import "../../theme/colors";

.contact-item {
  border     : 1px solid $primary;
  background : $primary;
  color      : #000;
  padding    : 2.5rem 1rem;
  cursor     : pointer;
  transition : .3s;

  .contact-item-header {
	text-align : center;
	font-size  : 1rem;
  }

  .contact-item-desc {
	margin-top : 0.5rem;
	text-align : center;
	font-size  : 0.75rem;
  }

  &.active {
	background : darken($primary, 20%);
	border     : 1px solid darken($primary, 20%);
	color      : $white;
  }

  &.inactive {
	background : #E6E6E6;
	border     : 1px solid #E6E6E6;
	color      : #000;
  }
}

.contact-item-content {
  .content {
	border        : 1px solid $primary;
	padding       : 2rem;
	margin-bottom : 1rem;
	background    : $white;
  }

  .contact-form {
	border        : 1px solid $primary;
	background    : #E6E6E6;
	padding       : 1.5rem;
	margin-bottom : 1rem;

	.header {
	  text-align    : center;
	  margin-bottom : 2rem;

	  i {
		font-size     : 4.5rem;
		margin-bottom : 0.75rem;
		color         : $primary;
	  }

	  h4 {
		margin-bottom : 1.25rem;
	  }

	  p {
		color         : #878384;
		margin-bottom : 0;
	  }
	}

	.btn {
	  margin-top : 0.5rem;
	  font-size  : 1rem !important;
	}

	.callInfo {
	  margin-bottom : 2rem;

	  .number {
		font-size : 2rem;
	  }

	  .supportHours {
		color      : #878384;
		margin-top : 0.75rem;
	  }
	}
  }
}

.contact-tree-row {
  &.even {
	//background: transparentize($primary,0.8);
  }
}
