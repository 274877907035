@import "../../theme/colors.scss";

.crop-container {
  position : relative;
  height   : 80vh;
}

.crop-container-holder {
  background : #4C4C4C;
}

.controls {
  position   : relative;
  padding    : 20px;
  background : #FFF;

  .rs-slider-bar {
	height           : 6px;
	border-radius    : 3px;
	background-color : $primary;
	cursor           : pointer;
  }

  .rs-slider-bar:hover {
	background-color : transparentize($primary, 0.4);
  }

  .rs-slider-handle::before {
	content            : '';
	position           : absolute;
	width              : 12px;
	height             : 12px;
	border-radius      : 50%;
	border             : 2px solid $primary;
	background-color   : #FFF;
	margin-left        : -6px;
	cursor             : pointer;
	-webkit-transition : background-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
	transition         : box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  }
}
