@import "../../theme/colors";

.language-select {
  .dropdown-toggle {
	font-size  : 1.75rem;
	border     : none;
	padding    : 0;
	background : transparent !important;
	outline    : none;

	&.btn-primary {
	  background : transparent !important;
	  color      : $primary !important;

	  &.disabled, &:disabled {
		background : transparent !important;
		color      : $primary !important;
	  }

	  &:hover, &:focus, &:active {
		background : transparent !important;
		color      : $primary !important;
		box-shadow : none !important;
	  }

	}
  }

  .dropdown-menu {
	min-width : auto;
	z-index: 9999999;
  }

  .dropdown-item {
	padding : 5px 20px;

	&.active, &:active {
	  color            : $primary !important;
	  text-decoration  : none;
	  background-color : #F8F9FA;
	}
  }

  .active-language-title {
	font-size : 16px;
  }
}

button#langauge {
  display     : flex;
  align-items : center;
}
