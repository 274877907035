@import "../../theme/colors";

.chat-window {
  padding    : 0;
  margin-top : 0;
  border     : 1px solid rgba(0, 0, 0, 0.1);
  background : #FFF;

  .message-list-holder {
	max-height : calc(100vh - 550px);
	overflow   : auto;
	padding    : 1rem;
	background : rgba(0, 0, 0, 0.1);
  }

  .rce-container-mbox {
	margin-bottom : 10px;
  }

  .rce-mbox {
	position      : relative;
	background    : white;
	border-radius : 0;
	margin-bottom : 10px;
	box-shadow    : 5px 5px 5px 0 rgba(0, 0, 0, .2);
	font-weight   : 300;
  }

  .rce-mbox-time {
	font-size : 11px;
  }

  .rce-mbox-right {
	background : $primary;
	color      : #FFF;
	box-shadow : -5px 5px 5px 0px rgba(0, 0, 0, .2);

	.rce-mbox-time {
	  color : #FFF;
	}
  }

  .rce-mbox-right-notch {
	fill : $primary;
  }

  .rce-mbox-body {
	padding-bottom : 20px;
  }

  .rce-mbox-text {
	white-space : pre-wrap;

  }
}

.newMessage-container {
  margin     : 0;
  background : $primary;
  padding    : 1rem;

  .from-label-group {
	background : #FFF;
	margin     : 0;

	textarea {
	  border  : none;
	  padding : 10px 0;
	}
  }

  .btn {
	border-radius : 0.75rem;
	padding       : 5px;
	font-size     : 1rem;
  }
}

.send-action {
  color      : $primary;
  cursor     : pointer;
  width      : 100%;
  height     : 100%;
  transition : 0.4s;
  padding    : 1rem 0;

  i {
	font-size : 3rem;
  }

  .action-title {
	font-size : 0.9rem;
  }

  &:hover {
	background : $primary;
	color      : #FFF;
  }
}

@media (max-width : 991px) {
  .chat-window {
	.message-list-holder {
	  max-height : calc(100vh - 200px);
	}
  }
}

.loading-holder {
  width      : 100%;
  background : #E5E5E5;

  .bar {
	height     : 5px;
	transition : 1s linear;
	background : $primaryLight;
  }
}
