@import "../../theme/colors";

.model-search {
  background    : $primary;
  margin-bottom : 3rem;
  padding       : 1.5rem;

  .form-control {
	width        : calc(75% - 1rem);
	margin-right : 1rem;
	padding      : 0.75rem 0.75rem;
	height       : calc(1.80em + 1rem + 2px);

  }

  .btn-black {
	font-size : 1rem;
	width     : calc(25%);
  }
}

@media (max-width : 768px) {
  .model-search {
	.form-control {
	  width         : 100%;
	  margin-right  : 0;
	  margin-bottom : 1rem;

	}

	.btn-black {
	  width : 100%;
	}
  }
}
