@import "../../theme/colors";

.my-uploadfield {
  //max-width: 400px;
  //max-height: 400px;
  //display: block;
  //margin: 0 auto;

  .dzu-dropzone {
	border     : 1px dashed $primary;
	min-height : 100px;
	//max-width: 400px;
	//max-height: 400px;
	//padding: 50%;

	.dzu-inputLabel {
	  margin      : 0;
	  font-weight : lighter;
	  font-size   : 1rem;
	}

	.dzu-previewContainer {
	  border     : none;
	  min-height : 100px;
	}
  }

  .dzu-currentFiles {
	margin-top : 10px;

	.rs-list-item {
	  padding    : 0;
	  box-shadow : none;
	}
  }

  .dzu-previewContainer {
	border  : 1px solid $primary;
	padding : 10px;

	.dzu-previewStatusContainer {
	  margin-right : 1rem;
	  font-size    : 1rem;
	  color        : $textLight;
	  min-height   : 100px;

	  .dzu-previewButton {
		margin-top  : -5px;
		margin-left : 2rem;
		min-height  : 1.5rem;
		min-width   : 1.5rem;
		color       : $primary;
		padding     : 0;
		font-size   : 1rem;

		&:hover {
		  color           : $danger !important;
		  text-decoration : none;
		}
	  }
	  .dzu-previewButton-edit, .dzu-previewButton-delete{
		width: auto;
		margin: 0;
		padding-left: 10px;
		padding-right: 10px;
		text-align: center;
		height: auto;
		.button-text{
			margin-top: 0px;
		}
	  }
	  .dzu-previewButton-edit:hover {
		color : $success !important;
	  }
	}

	.dzu-previewImage {
	  max-height    : 80px;
	  max-width     : 200px;
	  border-radius : 0;
	}

	&:not(:last-child) {
	  border-bottom : none;
	}
  }
}

.crop-holder {
  position        : fixed;
  left            : 0;
  top             : 0;
  width           : 100vw;
  height          : 100vh;
  z-index         : 9999;
  background      : rgba(0, 0, 0, 0.5);
  display         : flex;
  justify-content : center;
  padding         : 30px 0;

  .crop-bg {
	background : #FFF;
	padding    : 20px 20px 0;
	max-width  : 100%;
	width      : 800px;

	.close-crop {
	  position    : absolute;
	  top         : 15px;
	  right       : 30px;
	  font-size   : 2rem;
	  line-height : 1;
	  color       : #FFF;
	  z-index     : 999999;
	  cursor      : pointer;
	  background  : $primary;
	  padding     : 10px;
	}
  }

}

.reorderArrow {
  display         : flex;
  cursor          : pointer;
  justify-content : center;
  font-size       : 1.25rem;
  color           : $primary;
}

@media (max-width : 565px) {
  .my-uploadfield .dzu-previewContainer .dzu-previewImage {
	max-height    : 150px;
	max-width     : 200px;
	border-radius : 0;
	text-align    : center;
	display       : block;
	margin        : 0 auto;
  }
  .my-uploadfield .dzu-previewContainer .dzu-previewStatusContainer {
	margin-right : 0;
	font-size    : 1rem;
	color        : #7A7E8A;
	min-height   : 100px;
	width        : 100%;

  }
  .crop-holder {
	width  : 100vw;
	height : 100%;
	padding: 0;
  }
}
