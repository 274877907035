@import "../../theme/colors";

.nav-icons {
  a.nav-icon {
	position        : relative;
	display         : flex;
	flex-direction  : column;
	font-size       : 1.75rem;
	align-items     : center;
	text-decoration : none !important;
	margin          : 0 0.75rem;
	&.active{
	  color : darken($primary, 25%) !important;
	}

	span {
	  display    : block;
	  margin-top : 5px;
	  font-size  : 16px;
	}

	i {
	  font-size : 1.75rem
	}
  }

  i:hover {
	color : darken($primary, 15%) !important;
  }
}

.chat-counter {
  position      : absolute;
  top           : -10px;
  right         : -5px;
  height        : 20px;
  width         : 20px;
  color         : #FFF;
  background    : #000;
  border-radius : 100%;
  text-align    : center;
  font-size     : 11px;
  line-height   : 21px;
}

@media (max-width : 991px) {
  .navbar-brand {
	width         : 100%;
	text-align    : center;
	margin-bottom : 1rem;
  }
  .nav-icons {
	flex-wrap       : wrap;
	width           : 100%;
	justify-content : center;

	a.nav-icon {
	  font-size : 1rem;
	  margin    : 0.5rem;

	  span {
		display    : block;
		margin-top : 5px;
		font-size  : 12px;
	  }

	  i {
		font-size : 1.25rem
	  }
	}
  }
}
