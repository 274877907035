@import "../../theme/colors";
@import url("https://use.typekit.net/sui0tfd.css");

.chat-item {
  border        : 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom : 1rem;
  background    : #FFF;
  position      : relative;

  .rce-citem-body{
	padding: 10px 0;
  }


  &.inChatWindow {
	margin-bottom : 0;
	border-bottom : none;


	.rce-citem-body--top-time, .rce-citem-body--bottom {
	  display : none;
	}

	.rce-citem:hover {
	  background : #FFF;
	}
  }

  &.reported {
	background : #EEE;

	.rce-citem {
	  background : #EEE;
	}

	.rce-citem:hover {
	  background : #EEE;
	}
  }

  .chat-confirm-delete {
	position   : absolute;
	top        : 0;
	left       : 0;
	width      : 100%;
	background : #FFF;
	z-index    : 99;
	height     : 100%;

	p {
	  margin-bottom : 10px;
	}

	.buttons {
	  cursor      : pointer;
	  font-size   : 1.5rem;
	  line-height : 1;
	}
  }

  .rce-container-citem {
	width : calc(100% - 40px);

	.model-name {
	  font-family  : aw-conqueror-didot, serif;
	  font-size    : 1.25rem;
	  display      : inline-block;
	  margin-right : 10px;
	}
	.model-name-subtext{
	  font-size: 11px;
	  line-height: 1.1;
	  display: block;
	}

	.rce-avatar-container {
	  &.circle {
		border-radius : 0;
	  }

	  &.large {
		width  : 60px;
		height : 60px;
	  }

	  img {
		border-radius : 0;
	  }
	}

	.rce-citem-body--bottom-title {
	  font-weight : 300;
	}

	.rce-citem-body--bottom-status span {
	  line-height : 1;
	  width       : 20px;
	  height      : 20px;
	}

	.rce-citem {
	  height : 100%;
	}

	.rce-citem-body {
	  border-bottom : none;
	}
  }

  &.unread {
	.rce-container-citem {
	  .rce-citem-body--bottom-title {
		color : $primary;
	  }
	}
  }

  .chat-actions {
	i {
	  padding    : 0.75rem;
	  cursor     : pointer;
	  color      : #FFF;
	  background : #FFF;
	  transition : 0.4s;
	}

	i.report {
	  color : #000;
	}

	i.delete {
	  color : $danger;
	}

	i:hover {
	  background : rgba(0, 0, 0, 0.8);
	  color      : #FFF;
	}
  }

  &.reported {
	i {
	  background : #EEE;
	}
  }
}

.close-choose{
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 1.25rem;
  background: $danger;
  color: #fff;
  cursor: pointer;
}


@media (max-width: 400px) {
  .rce-citem-body--top{
	display: flex;
	flex-direction: column-reverse;
  }
  .rce-citem-body--top-time{
	text-align: right;
  }
  .chat-confirm-delete{
	height: auto !important;
	background: #fff;
	border: 1px solid $primary;
  }
}
