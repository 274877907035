@import "./colors";
@import url("https://use.typekit.net/sui0tfd.css");

body {
  font-family : tenon, sans-serif;
  font-weight : 300 !important;
  background  : #FFF url("../assets/img/pattern_topmodelcast-min.png") repeat top left;
  min-height  : 100vh;
}

a {
  color : $primary;

  &:hover, &:active &:focus {
	color : darken($primary, 15%) !important;
  }

  &:visited {
	color : $primary;
  }
}

a.btn, .btn {
  padding       : 0.75rem 1.5rem;
  border-radius : 0;
  border        : 1px solid $primary;
  background    : $primary;
  color         : $white !important;
  transition    : 0.4s;
  font-weight   : 300;
  font-size     : 1.25rem;

  &.disabled, &:disabled {
	color        : $primary !important;
	border-color : $primary !important;
  }

  &:hover, &:focus, &:active {
	background   : $white !important;
	color        : $primary !important;
	border-color : $primary !important;
	box-shadow   : none;
  }


  &.btn-primary {
	background : $white;
	color      : $primary !important;

	&.disabled, &:disabled {
	  color        : $primary !important;
	  border-color : $primary !important;
	}

	&:hover, &:focus, &:active {
	  background   : $primary !important;
	  color        : $white !important;
	  border-color : $primary !important;
	}

  }

  &.btn-danger {
	background : #DC3545;
	color      : $white !important;

	&.disabled, &:disabled {
	  color        : $white !important;
	  border-color : #DC3545 !important;
	}

	&:hover, &:focus, &:active {
	  background   : #B62C3A !important;
	  color        : $white !important;
	  border-color : #DC3545 !important;
	}

  }

  &.btn-black {
	background : #000;
	color      : $white !important;

	&.disabled, &:disabled {
	  color        : $white !important;
	  border-color : #000 !important;
	}

	&:hover, &:focus, &:active {
	  background   : mix(#000, $white, 80) !important;
	  color        : $white !important;
	  border-color : #000 !important;
	}

  }

  &.btn-link {
	background : none;
	padding    : 0;
	border     : none;
	color      : $primary !important;
	font-size  : 1rem;

	&:hover {
	  color : darken($primary, 15%);
	}
  }

  &.btn-success {
	background : #7D7;
	color      : $white !important;

	&.disabled, &:disabled {
	  color        : $white !important;
	  border-color : #7D7 !important;
	}

	&:hover, &:focus, &:active {
	  background   : mix(#7D7, $white, 80) !important;
	  color        : $white !important;
	  border-color : #7D7 !important;
	}

  }
}

.form-control {
  width            : 100%;
  color            : #000;
  font-weight      : 300;
  background-color : #FFF;
  background-clip  : padding-box;
  border           : 1px solid #E1E1E1;
  border-radius    : 0;
  height           : calc(1.5em + 1rem + 2px);
  padding          : 0.5rem 0.75rem;
  outline          : none !important;

  &:focus {
	border     : 1px solid #E1E1E1;
	box-shadow : none;
  }

  &::-webkit-input-placeholder {
	color       : darken(#E1E1E1, 12%);
	font-weight : 300;
	opacity     : 1;
  }

  &:-moz-placeholder {
	color       : darken(#E1E1E1, 12%);
	font-weight : 300;
	opacity     : 1;
  }

  &::-moz-placeholder {
	color       : darken(#E1E1E1, 12%);
	font-weight : 300;
	opacity     : 1;
  }

  &:-ms-input-placeholder {
	color       : darken(#E1E1E1, 12%);
	font-weight : 300;
	opacity     : 1;
  }
}

.from-label-group {
  border        : 1px solid #E1E1E1;
  border-radius : 0;
  padding       : 10px;
  background    : #FFF;

  label {
	color         : lighten(#000, 60%);
	font-size     : 14px;
	line-height   : 14px;
	display       : block;
	cursor        : text;
	transition    : all .25s ease;
	margin-bottom : 0;
  }

  input.form-control {
	border-color : transparent !important;
	padding      : 3px 0;
	height       : auto;
	line-height  : 1;
  }

  &.allow-border {
	input.form-control {
	  border-color : transparent !important;
	  margin-top   : 3px;
	  padding      : 7px 0;
	  height       : auto;
	  line-height  : 1;
	}
  }

  select.form-control {
	border-color : transparent !important;
	margin-top   : 3px;
	padding      : 7px 0;
	height       : auto;
	line-height  : 1;
  }

  .rs-picker-default {
	margin-top : 3px;

	.rs-btn-default {
	  border-color : transparent !important;
	  padding      : 7px 0 !important;
	}

	.rs-picker-toggle-caret, .rs-picker-toggle-clean {
	  top : 5px !important;
	}

	.rs-picker-toggle-value {
	  line-height : 1;
	  margin-top  : 1px;
	}
  }

  p {
	font-size : 12px;
  }
}

.from-section-label {
  font-weight   : 600;
  font-size     : 1.25rem;
  margin-bottom : 10px;

  span.label-desc {
	display     : block;
	margin-top  : 10px;
	font-weight : 300;
	font-size   : 0.85rem;
	color       : lighten(#000, 60%);
  }
}

.location.form-control {
  min-height : 34px;
}

.form-group {
  width : 100%;
}

.alert {
  width : 100%;
}

.show.fade {
  opacity : 1;
}

.px-15 {
  padding-left  : 15px !important;
  padding-right : 15px !important;
}

.rs-picker-menu.fade:not(.show) {
  opacity : 1;
}

.rs-picker-default {
  a.rs-btn.rs-btn-default.rs-picker-toggle {
	border-color  : #E1E1E1;
	border-radius : 0;
	padding       : 0.5rem 0.75rem;

	&:hover {
	  border-color : #E1E1E1;
	}
  }
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color            : #FFF;
  border-color     : $primary;
  background-color : $primary;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color : #000;
}

.rs-picker-menu {
  position           : absolute;
  text-align         : left;
  z-index            : 7;
  border-radius      : 6px;
  background-color   : #FFF;
  -webkit-box-shadow : 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  box-shadow         : 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  overflow           : hidden;
  -webkit-transition : none;
  transition         : none;
}

.rs-picker-select-menu-item {
  display            : block;
  padding            : 5px 10px;
  clear              : both;
  font-weight        : normal;
  line-height        : 1;
  font-size          : 1rem;
  color              : #575757;
  cursor             : pointer;
  -webkit-transition : color 0.3s linear, background-color 0.3s linear;
  transition         : color 0.3s linear, background-color 0.3s linear;
  text-decoration    : none;
  width              : 100%;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-active, .rs-picker-select-menu-item.rs-picker-select-menu-item-active:hover {
  color            : $primary;
  background-color : #F2FAFF;
}

.admin-tab-content {
  border-left   : 1px solid #DEE2E6;
  border-right  : 1px solid #DEE2E6;
  border-bottom : 1px solid #DEE2E6;
}

.cursor-pointer {
  cursor : pointer;
}
.cursor-pointer-imp{
  cursor : pointer !important;
}

.bg-gray {
  background : $lightBackground;
}

.text-primary {
  color : $primary !important;
}

.rs-slider-handle::before {
  content            : '';
  position           : absolute;
  width              : 12px;
  height             : 12px;
  border-radius      : 50%;
  border             : 2px solid $primary;
  background-color   : #FFF;
  margin-left        : -6px;
  cursor             : pointer;
  -webkit-transition : background-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition         : box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.rs-slider-progress-bar {
  position         : absolute;
  height           : 6px;
  border-radius    : 3px 0 0 3px;
  background-color : $primary;
}

.header-padding {
  h1 {
	margin-bottom : 3rem;
  }
}

h1, h3, h4 {
  font-weight : 300;
}

p {
  font-weight : 300;
}

.container {
  max-width : 900px;
}

.container-fluid {
  padding : 0 5rem;
}

@media (max-width : 1199px) {
  .container-fluid {
	padding : 0 1rem;
  }
}

.full-height-center {
  min-height : calc(100vh - 185px);
}

.bg-primary {
  background-color : $primary !important;
}

.alternative-font {
  font-family : aw-conqueror-didot, serif;
}

.font-size-big {
  font-size : 5rem;
}

.bg-black {
  background-color : #000;
}

.small-btn {
  font-size : 1rem !important;
  padding   : 0.75rem !important;
}

.borderless-btn {
  border      : none !important;
  background  : transparent !important;
  line-height : 1;

  &.btn:hover, &.btn:focus, &.btn:active {
	background : transparent !important;
	color      : darken($primary, 15%) !important;
  }
}

.rs-picker-value-count {
  background : $primary;
}

.rs-checkbox-wrapper::before {
  border : 1px solid $primary;
}


.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before, .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color     : $primary;
  background-color : $primary;
}

.date-group {
  .rs-picker-toggle {
	padding : 0.75rem 0.75rem !important;
  }

  .rs-picker-toggle-caret {
	top : 13px !important;
  }

  .rs-picker-toggle-placeholder {
	font-weight : 300;
	opacity     : 0.7;
  }
}

.horizontal-slider {
  .normal-track {
	height        : 6px;
	background    : $primary;
	border-radius : 15px;
  }

  .small-thumb {
	outline       : none;
	cursor        : pointer;
	margin-top    : -8px;
	height        : 20px;
	width         : 20px;
	background    : #FFF;
	border-radius : 100%;
	border        : 2px solid $primary;
  }

  .white-track {
	height        : 2px;
	background    : #FFF;
	border-radius : 15px;
  }

  &.main-white {
	.white-track-1 {
	  height           : 20px;
	  border-radius    : 3px 0 0 3px;
	  margin-top       : -9px;
	  background-color : rgba(255, 255, 255, 0.2);

	  &:after {
		content    : "";
		position   : absolute;
		left       : 0;
		top        : 9px;
		height     : 2px;
		width      : 100%;
		background : #FFF;
	  }
	}
  }

  .white-thumb {
	outline    : none;
	cursor     : pointer;
	margin-top : -18.5px;
	height     : 40px;
	width      : 15px;
	background : #FFF;
	//border: 1px solid $primary;
  }
}

.payment-container {
  .active {
	background : darken($primary, 20%) !important;
	border     : 1px solid darken($primary, 20%) !important;
  }

  .payment-holder {
	p {
	  margin : 0;
	}

	min-height : 200px;
	background : #E6E6E6;
	padding    : 2rem;
  }

  .paymentButton {
	margin-top: 15px;
	margin-bottom: 15px;
	padding : 1.5rem 3rem;

	.platin-img {
	  display : none;
	}

	img {
	  height  : 40px;
	  display : block;
	  margin  : 0 auto;
	  object-fit: contain;
	}

	&:hover {
	  .platin-img {
		display : block;
	  }

	  .white-img {
		display : none;
	  }
	}
  }

  .paymentInfo {
	img {
	  height        : 40px;
	  margin-bottom : 25px;
	}

	h4 {
	  font-size     : 1.25rem;
	  margin-bottom : 15px;
	  font-weight   : bolder;
	}

	p {
	  margin-bottom : 20px;
	}
  }
}

#stripe-submit {
  background : $primary;
  color      : #FFF;
}

.pdf-iframe-holder {
  iframe {
	width  : 100%;
	height : 100vh;
  }
}

.character-counter {
  position   : absolute;
  bottom     : 5px;
  right      : 20px;
  font-size  : 0.8rem;
  z-index    : 1;
  background : #FFF;

  &.error {
	color : $danger;
  }
}

.from-label-group.showBorder {
  border : 1px solid $danger !important;
}

.unit-input.showBorder {
  .from-label-group {
	border : 1px solid $danger !important;
  }
}

.MuiFormControl-root {
  width : 100%;
}

.MuiInput-underline:before, .MuiInput-underline:after {
  display : none !important;
}

.MuiButtonBase-root {
  outline : none !important;
  border  : none !important;
}

.MuiPickersToolbar-toolbar, .MuiPickersDay-daySelected {
  background-color : $primary !important;
}

.MuiButton-textPrimary {
  color : $primary !important;
}

.MuiTypography-colorPrimary {
  color : $primary !important;
}
.MuiFormControl-marginNormal{
  margin: 0 !important;
  margin-top: 5px !important;
}

@media (max-width: 576px) {
  .h1, h1 {
	font-size: 2rem;
  }
  .button-row.model-reg-button{
	.btn{
	  max-width: 49%;
	}
  }
}

#ideal-form{
  .form-row{
	margin-bottom: 15px;
	label{
	  display: block;
	  width: 100%;
	  font-weight: bold;
	}
	input{
	  height: 40px;
	  width: 100%;
	  color: #000;
	  padding: 10px 12px;
	  background-color: white;
	  border: 1px solid transparent;
	  box-shadow: 0 1px 3px 0 $primary;
	  -webkit-transition: box-shadow 150ms ease;
	  transition: box-shadow 150ms ease;
	  outline: none !important;
	  margin-bottom: 15px;
	}
  }
  button{
	outline: none!important;
	padding: 0.5rem;
	display: block;
	margin: 0 auto;
	background : $primary;
	color      : #FFF;
  }
}
#ideal-form input, .StripeElement{
  padding: 0;
  color: $primary !important;
}

#sepa-form{
  .form-row{
	margin-bottom: 15px;
	.col{
	  label{
		padding-left: 0px;
		padding-right: 0px;
	  }

	}
	label{
	  padding-left: 7.5px;
	  padding-right: 7.5px;
	  width: 100%;
	}
	#iban-element{
	  margin-left: 7.5px;
	  margin-right: 7.5px;
	  width: 100%;
	}
  }
  button{
	outline: none!important;
	padding: 0.5rem;
	display: block;
	margin: 0 auto;
	background : $primary;
	color      : #FFF;
	margin-top: 15px;
	margin-bottom: 15px;
  }
}

#date-picker-dialog-helper-text{
  display: none;
}

.phone-group{
  label{
	margin-bottom: 5px;
  }
  input[type=tel]{
	max-width: calc(100% - 50px);
	margin-left: 50px;
  }
}

.rs-picker-tag{
  width: 100%;
  border-radius: 0;
  height: 40px;
}
.rs-picker-input:not(.rs-picker-disabled):hover, .rs-picker-input.rs-picker-focused {
  border-color: $primary;
}

.video-react-big-play-button{
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%,-50%);
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: $primary !important;
}


.rtl-content{
  #inputEmailRepeat, #inputEmail{
	text-align: right;
  }
  text-align: right;
  .text-left{
	text-align: right !important;
  }
  .phone-group{
	input[type=tel]{
	 text-align: right;
	}
  }
  .ml-2{
	margin-left: 0;
	margin-right: 0.5rem;
  }
}
.filter-unit-input{
  .form-label{
	display: none;
  }
  .from-label-group{
	background: transparent;
	padding: 2px 15px;
	input{
	  color: #fff;
	  background: transparent !important;
	  -moz-appearance: textfield;
	  /* Chrome, Safari, Edge, Opera */
	  &::-webkit-outer-spin-button,
	  &::-webkit-inner-spin-button {
		-webkit-appearance: none;
	  }
	}
  }
}

.rs-picker-menu{
  z-index: 1000;
}
